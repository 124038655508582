/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import { getCleanFilename } from 'helpers/mediaUtils'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseWorkflowDot from '../CaseWorkflowDot'
import CaseWorkflowStyledComponents from '../CaseWorkflowStyledComponents'
import CaseWorkflowDownloadButton from '../CaseWorflowButtons/CaseWorkflowDownloadButton'
import CaseWorkflowJalonText from '../CaseWorkflowJalonText'

/* Type imports ------------------------------------------------------------- */
import type { JalonFinDeTravaux } from 'API/__generated__/Api'

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowWorkEndJalonCardProps {
  jalon: JalonFinDeTravaux;
}

const CaseWorkflowWorkEndJalonCard: React.FC<CaseWorkflowWorkEndJalonCardProps> = ({ jalon }) => {

  return (
    <Card>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Title>
          Fin de travaux
        </CaseWorkflowStyledComponents.Title>
        <div />
        <CaseWorkflowJalonText
          jalon={jalon.type.code}
          date={jalon.dateValidation || ''}
          state={jalon.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonPhotoApresTravaux.etat}
            small
          />
          Photos après travaux
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonPhotoApresTravaux.photoApresTravaux.length > 0 ?
            <CaseWorkflowStyledComponents.PicturesContainer>
              {
                jalon.sousJalonPhotoApresTravaux.photoApresTravaux.map((file, index) => (
                  <CaseWorkflowDownloadButton
                    key={`${file.url}-${index}`}
                    name={getCleanFilename(file.libelle || '', file.fileName || '')}
                    url={file.url || ''}
                    isPhoto
                  />
                ))
              }
            </CaseWorkflowStyledComponents.PicturesContainer> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonPhotoApresTravaux"
          date={jalon.sousJalonPhotoApresTravaux.dateValidation || ''}
          state={jalon.sousJalonPhotoApresTravaux.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonAttestationFinDeTravaux.etat}
            small
          />
          Attestation de fin de travaux
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux ?
            <CaseWorkflowDownloadButton
              name={getCleanFilename(jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux.libelle || '', jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux.nomDuFichier || '')}
              url={jalon.sousJalonAttestationFinDeTravaux.attestationFinDeTravaux.url || ''}
            /> :
            <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonAttestationFinDeTravaux"
          date={jalon.sousJalonAttestationFinDeTravaux.dateValidation || ''}
          state={jalon.sousJalonAttestationFinDeTravaux.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      <CaseWorkflowStyledComponents.CardContent>
        <CaseWorkflowStyledComponents.Line>
          <CaseWorkflowDot
            state={jalon.sousJalonAttestationTva.etat}
            small
          />
          Attestation de TVA
        </CaseWorkflowStyledComponents.Line>
        {
          jalon.sousJalonAttestationTva.attestationTva?.length ?
            jalon.sousJalonAttestationTva.attestationTva.map((file, index) => (
              <CaseWorkflowDownloadButton
                key={`${file.url}-${index}`}
                name={getCleanFilename(file.libelle || '', file.nomDuFichier || '')}
                url={file.url || ''}
              />
            )):
            <div />
        }
        <CaseWorkflowJalonText
          jalon="sousJalonAttestationTva"
          date={jalon.sousJalonAttestationTva.dateValidation || ''}
          state={jalon.sousJalonAttestationTva.etat}
        />
      </CaseWorkflowStyledComponents.CardContent>
      {
        jalon.sousJalonRapportRechercheDeFuite &&
          <CaseWorkflowStyledComponents.CardContent>
            <CaseWorkflowStyledComponents.Line>
              <CaseWorkflowDot
                state={jalon.sousJalonRapportRechercheDeFuite.etat}
                small
              />
              Rapport de recherche de fuite
            </CaseWorkflowStyledComponents.Line>
            {
              jalon.sousJalonRapportRechercheDeFuite.rapportRechercheDeFuite ?
                <CaseWorkflowDownloadButton
                  name={getCleanFilename(jalon.sousJalonRapportRechercheDeFuite.rapportRechercheDeFuite.libelle || '', jalon.sousJalonRapportRechercheDeFuite.rapportRechercheDeFuite.nomDuFichier || '')}
                  url={jalon.sousJalonRapportRechercheDeFuite.rapportRechercheDeFuite.url || ''}
                /> :
                <div />
            }
            <CaseWorkflowJalonText
              jalon="sousJalonRapportRechercheDeFuite"
              date={jalon.sousJalonRapportRechercheDeFuite.dateValidation || ''}
              state={jalon.sousJalonRapportRechercheDeFuite.etat}
            />
          </CaseWorkflowStyledComponents.CardContent>
      }
    </Card>
  )
}

export default CaseWorkflowWorkEndJalonCard
