/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetCaseWorkflowQuery,
  useGetDeclineInvoiceReasonListQuery,
  usePatchDeclineInvoiceMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
} from '@mui/material'
import {
  Select,
  TextField,
} from 'formik-mui'
import { toast } from 'react-toastify'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LongButton from 'components/LongButton/LongButton'
import BackButton from 'components/BackButton/BackButton'
import CloseButton from 'components/CloseButton/CloseButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
  type FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import {
  EtatFacture,
  type ChangerStatutFacturePartialUpdatePayload,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const invoiceSchema = Yup.object().shape<Shape<ChangerStatutFacturePartialUpdatePayload>>({
  factureEtat: Yup.mixed<EtatFacture>().required(''),
  commentaire: Yup.string().when('factureEtat', {
    is: EtatFacture.Refuse,
    then: () => Yup.string().required('Le commentaire est obligatoire'),
  }),
  motifRefus: Yup.number().when('factureEtat', {
    is: EtatFacture.Refuse,
    then: () => Yup.number().min(0, 'Le motif est obligatoire').required('Le motif est obligatoire'),
  }),
})

type InvoiceForm = FormikContextType<ChangerStatutFacturePartialUpdatePayload>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.media.mobile.portrait} {
    font-size: 1.2rem;
    margin-top: 0px;
    margin-bottom: -20px;
  }
`

const DialogContentContainer = styled(DialogContent)`
  padding-bottom: 0;
`

const DialogActionContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px;
`

const DuoButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column-reverse;
  }
`

/* Component declaration ---------------------------------------------------- */
interface InvoicePageProps {}

const InvoicePage: React.FC<InvoicePageProps> = () => {
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ open, setOpen ] = useState<boolean>(false)

  const {
    currentData: workflow,
    isFetching: isFetchingWorkflow,
  } = useGetCaseWorkflowQuery(caseId)
  const {
    currentData: reasonList = [],
    isFetching: isFetchingReasonList,
  } = useGetDeclineInvoiceReasonListQuery()
  const [
    declineInvoice,
    { isLoading: isSendingInvoiceState },
  ] = usePatchDeclineInvoiceMutation()

  const onSubmit = (data: ChangerStatutFacturePartialUpdatePayload, { setSubmitting }: FormikHelpers<ChangerStatutFacturePartialUpdatePayload>) => {
    declineInvoice({ invoiceId: `${caseId}-${workflow?.jalonFacture.facture?.sequence}`, data })
      .then((response) => {
        if (isApiError(response)) {
          setSubmitting(false)
          toast.error(`Une erreur est survenue lors du refus de la facture.`)
        } else if (response) {
          toast.success(`Le facture à bien été refusée.`)
          navigate(`/dossiers/${caseId}`)
        }
      })
      .catch(console.error)
  }

  const formikForm: InvoiceForm = useForm<ChangerStatutFacturePartialUpdatePayload>(
    {
      initialValues: {
        factureEtat: EtatFacture.Refuse,
        commentaire: '',
        motifRefus: -1,
      },
      onSubmit: onSubmit,
      validationSchema: invoiceSchema,
    },
  )

  const onValidate = () => {
    formikForm.setSubmitting(true)
    declineInvoice({ invoiceId: `${caseId}-${workflow?.jalonFacture.facture?.sequence}`, data: { factureEtat: EtatFacture.Valide }})
      .then((response) => {
        if (isApiError(response)) {
          formikForm.setSubmitting(false)
          toast.error(`Une erreur est survenue lors de l'acceptation de la facture.`)
        } else if (response) {
          toast.success(`La facture à bien été acceptée.`)
          navigate(`/dossiers/${caseId}`)
        }
      })
      .catch(console.error)
  }

  return (
    <div>
      <BackButton onClick={() => navigate(`/dossiers/${caseId}`)}>
        Retourner au suvi
      </BackButton>
      <LargeTitle>
        Facture
        <DuoButtonContainer>
          <LongButton
            variant="outlined"
            onClick={() => setOpen(true)}
            disabled={isSendingInvoiceState}
          >
            Refuser
          </LongButton>
          <LongButton
            variant="contained"
            onClick={onValidate}
            disabled={isSendingInvoiceState}
          >
            Accepter
          </LongButton>
        </DuoButtonContainer>
      </LargeTitle>
      {
        isFetchingWorkflow ?
          <CircularProgress /> :
          workflow?.jalonFacture.piecejointe &&
            <div>
              <iframe
                src={workflow.jalonFacture.piecejointe?.url || ''}
                width="100%"
                height="700px"
                title={workflow.jalonFacture.piecejointe?.libelle ?? 'Facture'}
              />
            </div>
      }
      {
        open &&
          <Dialog
            open
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth="sm"
          >
            <Form form={formikForm}>
              <DialogTitleContainer>
                Refuser la facture
                <CloseButton handleClose={() => setOpen(false)} />
              </DialogTitleContainer>
              <DialogContentContainer>
                <FormBoldTitle required>
                  Motif
                </FormBoldTitle>
                <Field
                  component={Select}
                  name="motifRefus"
                  displayEmpty
                  disabled={isFetchingReasonList}
                  size="small"
                >
                  <MenuItem value={-1}>
                    Sélectionner
                  </MenuItem>
                  {
                    reasonList.map((value, index) => (
                      <MenuItem
                        value={value.id}
                        key={`${value.id}-${index}`}
                      >
                        {value.libelle}
                      </MenuItem>
                    ))
                  }
                </Field>
                <FormBoldTitle required>
                  Commentaire
                </FormBoldTitle>
                <Field
                  component={TextField}
                  placeholder="Votre message"
                  name="commentaire"
                  size="small"
                  rows={3}
                  multiline
                />
              </DialogContentContainer>
              <DialogActionContainer>
                <LongButton
                  variant="outlined"
                  onClick={() => setOpen(false)}
                >
                  Annuler
                </LongButton>
                <LongButton
                  variant="contained"
                  type="submit"
                >
                  Valider
                </LongButton>
              </DialogActionContainer>
            </Form>
          </Dialog>
      }
      <Footer />
    </div>
  )
}

export default InvoicePage
