/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useParams } from 'react-router-dom'
import { useGetCaseWorkflowQuery } from 'store/api'

/* Component imports -------------------------------------------------------- */
import { CircularProgress } from '@mui/material'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import CaseWorkflowDot from './CaseWorkflowComponents/CaseWorkflowDot'
import CaseWorkflowJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflowJalonCard'
import CaseWorkflowMissionJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow1MissionJalonCard'
import CaseWorkflowContactJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow2ContactJalonCard'
import CaseWorkflowInterventionJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow2InterventionJalonCard'
import CaseWorkflowMeasureRdvJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow3MeasureRdvJalonCard'
import CaseWorkflowPostInterventionJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow3PostInterventionJalonCard'
import CaseWorkflowMeasureJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow5PostMeasureJalonCard'
import CaseWorkflowWorksRdvJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow7WorksRdvJalonCard'
import CaseWorkflowWorkEndJalonCard from './CaseWorkflowComponents/CaseWorkflowCards/CaseWorkflow8WorkDoneJalonCard'

/* Styled components -------------------------------------------------------- */
interface LineContainerProps {
  last?: boolean;
}

const LineContainer = styled.div<LineContainerProps>`
  display: flex;
  align-items: center;
  border-left: 2px solid ${(props) => props.theme.colors.grey};
  margin-left: 10px;
  padding-left: 20px;
  padding-bottom: ${(props) => props.last ? '0px' : '30px'};

  .MuiPaper-root {
    width: 100%;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseWorkflowPageProps {}

const CaseWorkflowPage: React.FC<CaseWorkflowPageProps> = () => {
  const { caseId = '' } = useParams<{caseId: string}>()

  const {
    currentData: workflow,
    isFetching: isFetchingWorkflow,
  } = useGetCaseWorkflowQuery(caseId)

  const quoteComment = (refuseMotivation?: string, total?: number) => {
    const montant = total ? `\nMontant TTC : ${total.toFixed(2)}€` : ''
    return `${montant}\n${refuseMotivation || ''}`
  }
  return (
    <div>
      <LargeTitle>
        Suivi Dossier
      </LargeTitle>
      {
        isFetchingWorkflow ?
          <CircularProgress /> :
          workflow &&
            <React.Fragment>
              <LineContainer>
                <CaseWorkflowDot state={workflow.jalonCreation.etat} />
                <CaseWorkflowJalonCard
                  jalon={
                    {
                      ...workflow.jalonCreation,
                      libelle: 'Missionnement assureur',
                      date: workflow.jalonCreation.dateAnnulation ?? (workflow.jalonCreation.dateCreation || ''),
                    }
                  }
                  caseId={caseId}
                  isUrgentCase={workflow.dossierUrgent}
                />
              </LineContainer>
              <LineContainer>
                <CaseWorkflowDot state={workflow.jalonMissionnement.etat} />
                <CaseWorkflowMissionJalonCard jalon={workflow.jalonMissionnement} />
              </LineContainer>
              {
                workflow.jalonIntervention && workflow.jalonPostIntervention &&
                  <>
                    <LineContainer>
                      <CaseWorkflowDot state={workflow.jalonIntervention.etat} />
                      <CaseWorkflowInterventionJalonCard jalon={workflow.jalonIntervention} />
                    </LineContainer>
                    <LineContainer>
                      <CaseWorkflowDot state={workflow.jalonPostIntervention.etat} />
                      <CaseWorkflowPostInterventionJalonCard jalon={workflow.jalonPostIntervention} />
                    </LineContainer>
                  </>
              }
              {
                !workflow.jalonIntervention &&
                  <>
                    <LineContainer>
                      <CaseWorkflowDot state={workflow.jalonPriseDeContact.etat} />
                      <CaseWorkflowContactJalonCard jalon={workflow.jalonPriseDeContact} />
                    </LineContainer>
                    <LineContainer>
                      <CaseWorkflowDot state={workflow.jalonRDVMetre.etat} />
                      <CaseWorkflowMeasureRdvJalonCard jalon={workflow.jalonRDVMetre} />
                    </LineContainer>
                    <LineContainer>
                      <CaseWorkflowDot state={workflow.jalonPostRdvMetre.etat} />
                      <CaseWorkflowMeasureJalonCard jalon={workflow.jalonPostRdvMetre} />
                    </LineContainer>
                  </>
              }
              <LineContainer>
                <CaseWorkflowDot state={workflow.jalonDevis.etat} />
                <CaseWorkflowJalonCard
                  jalon={
                    {
                      ...workflow.jalonDevis,
                      libelle: 'Devis',
                      date: workflow.jalonDevis.dateAffichee || workflow.jalonDevis.dateModification || '',
                    }
                  }
                  quoteState={workflow.jalonDevis.devis?.etatDuDevis?.code}
                  caseId={caseId}
                  comment={quoteComment(workflow.jalonDevis.devis?.motifRefusDevis || '', workflow.jalonDevis.devis?.piedDuDevis?.montantTTC || undefined)}
                />
              </LineContainer>
              {
                !workflow.jalonIntervention &&
                  <>
                    <LineContainer>
                      <CaseWorkflowDot state={workflow.jalonRDVTravaux.etat} />
                      <CaseWorkflowWorksRdvJalonCard jalon={workflow.jalonRDVTravaux} />
                    </LineContainer>
                    <LineContainer>
                      <CaseWorkflowDot state={workflow.jalonFinDeTravaux.etat} />
                      <CaseWorkflowWorkEndJalonCard jalon={workflow.jalonFinDeTravaux} />
                    </LineContainer>
                  </>
              }
              <LineContainer>
                <CaseWorkflowDot state={workflow.jalonFacture.etat} />
                <CaseWorkflowJalonCard
                  jalon={
                    {
                      ...workflow.jalonFacture,
                      libelle: 'Facture',
                      date: workflow.jalonFacture.dateAffichee || workflow.jalonFacture.dateModification || '',
                    }
                  }
                  caseId={caseId}
                  invoiceState={workflow.jalonFacture.facture?.etatDeLaFacture?.code}
                  comment={workflow.jalonFacture.facture?.piedDeLaFacture?.montantTTC ? `\nMontant TTC : ${workflow.jalonFacture.facture?.piedDeLaFacture?.montantTTC.toFixed(2)}€` : ''}
                />
              </LineContainer>
              <LineContainer last>
                <CaseWorkflowDot state={workflow.jalonDossierTermine.etat} />
                <CaseWorkflowJalonCard
                  jalon={workflow.jalonDossierTermine}
                  caseId={caseId}
                />
              </LineContainer>
            </React.Fragment>
      }
      <Footer />
    </div>
  )
}

export default CaseWorkflowPage
